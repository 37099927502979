import React from "react";

import Application from "app/Application";
import { progressBarStop } from "app/ProgressBar";
import { ContextProvider } from "app/page/ContextProvider";
import Page from "app/page/Page";
import ErrorBoundaryOuter from "app/private/ErrorBoundaryOuter";
import { getEmbeddedJson } from "app/utils";

interface Props {}

const legacyContext = getEmbeddedJson("context");

export default class LegacyPage extends React.PureComponent<Props> {
    componentDidMount() {
        const legacyContents = document.getElementById("legacy-content");

        if (legacyContents) {
            legacyContents.style.display = "block";
        }
    }

    render() {
        const legacyContents = document.getElementById("legacy-content");
        let title;

        if (legacyContents) {
            title = legacyContents.getAttribute("data-title");
        }

        const pageProps = {
            ...this.props,
            title: title || "",
            legacyBreadcrumbs: getEmbeddedJson("breadcrumbs")
        };

        progressBarStop();

        return (
            <ErrorBoundaryOuter>
                <ContextProvider dynamicContext={legacyContext}>
                    <Application>
                        <Page {...pageProps}>
                            <div />
                        </Page>
                    </Application>
                </ContextProvider>
            </ErrorBoundaryOuter>
        );
    }
}